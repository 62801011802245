import React, { useState, useEffect } from "react";
// import axios from "axios";
import { kebabCase } from "lodash";
import { Link, graphql } from "gatsby";
import Footer from "../components/footer";
import Nav from "../components/nav";
import SEO from "../components/seo";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

interface ProductInterface {
  data: any;
}

const Product: React.FC<ProductInterface> = ({ data: { product } }) => {
  // const [rate, setRate] = useState(0);

  const rate = 14200;
  const title = `${product.topup_title} ${product.title} lewat PayPal`;
  const usd_price = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 5,
  }).format(Number(rateUSD(product.product_price, rate).toPrecision(2)));
  return (
    <>
      <SEO
        title={"Cara " + title}
        description={`${product.product_description}: ${title} dengan harga ${usd_price} sekarang`}
      >
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "BreadcrumbList", 
  "itemListElement": [{
    "@type": "ListItem", 
    "position": 1, 
    "name": "Home",
    "item": "https://rupiah.trade"  
  },{
    "@type": "ListItem", 
    "position": 2, 
    "name": "Produk",
    "item": "https://rupiah.trade/product"  
  },{
    "@type": "ListItem", 
    "position": 3, 
    "name": "${product.product_description}",
    "item": "https://rupiah.trade/product/${kebabCase(
      product.product_description
    )}"  
  },{
    "@type": "ListItem", 
    "position": 4, 
    "name": "${title}",
    "item": "https://rupiah.trade/product/${kebabCase(
      product.product_description
    )}/${kebabCase(product.product_nominal)}"  
  }]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "${title}",
  "image": "${product.imageURL}",
  "description": "${`${product.product_description}: ${title} dengan harga ${usd_price} sekarang`}",
  "brand": {
    "@type": "Brand",
    "name": "${product.product_description}"
  },
  "sku": "${kebabCase(product.product_description + product.title)}",
  "offers": {
    "@type": "Offer",
    "priceValidUntil": "2025-01-20",
    "url": "https://app.rupiah.trade/",
    "priceCurrency": "USD",
    "price": "${Number(rateUSD(product.product_price, rate).toPrecision(2))}",
    "availability": "https://schema.org/InStock"
  },
  "review": {
    "@type": "Review",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "5",
      "bestRating": "5"
    },
    "author": {
      "@type": "Person",
      "name": "Ismail"
    }
  },
 "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "worstRating": "1",
    "ratingCount": "2392"
  }
}`}
        </script>
      </SEO>
      <Nav />

      <nav className="mt-4 lg:mt-8 px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <ol
          className="inline text-sm text-slate-500 dark:text-slate-400"
          id="breadcumb"
        >
          <li className="inline after:content-['/'] after:px-2">
            <Link
              className="font-semibold text-primary-700 hover:text-primary-900 "
              to="/"
            >
              <span>Beranda</span>
            </Link>
          </li>
          <li className="inline after:content-['/'] after:px-2">
            <Link
              className="font-semibold text-primary-700 hover:text-primary-900 "
              to="/product"
            >
              <span>Produk</span>
            </Link>
          </li>

          <li className="inline after:content-['/'] after:px-2">
            <Link
              className="font-semibold  text-primary-700 dark:text-light-blue-500 hover:text-primary-900 dark:hover:text-light-blue-300"
              to={`/product/${kebabCase(product.product_description)}`}
            >
              <span>{product.product_description}</span>
            </Link>
          </li>

          <li className="inline">
            <span>{title}</span>
          </li>
        </ol>
      </nav>

      <div
        data-aos="fade-up"
        className="px-4 pt-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:flex"
      >
        {/* Gambar */}
        <div className=" lg:w-1/2 rounded-xl overflow-hidden">
          <GatsbyImage image={getImage(product.featuredImage)} alt={title} />
        </div>

        {/* Rincian */}
        <div className="lg:ml-8 mt-8 lg:mt-0 lg:w-1/2">
          <h1 className="text-gray-900 text-3xl font-bold">{title}</h1>
          <div className="mt-2 text-xl text-purple-700 font-bold">
            {usd_price}{" "}
            <span className="text-gray-500 text-base font-normal">
              {" "}
              —{" "}
              {new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                maximumSignificantDigits: 5,
              }).format(product.product_price)}
            </span>
          </div>

          {/* Deskripsi */}
          <div className="mt-8">
            <p>{product.product_details}</p>
          </div>
          <div className="mt-10">
            <a
              role="button"
              target="__blank"
              href="https://app.rupiah.trade"
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded-lg shadow-xl bg-primary-700 hover:bg-primary-500 hover:shadow-2xl focus:shadow-outline focus:outline-none"
            >
              Beli Sekarang
            </a>
          </div>
        </div>
      </div>

      {/* Cara */}
      <div
        data-aos="fade-up"
        className="px-4 pb-16 mt-8 pt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20 border-t border-gray-300"
      >
        <h2 className="text-xl font-bold">Cara {title}</h2>
        <div className="prose lg:prose-lg prose-slate max-w-full mt-8">
          <p>
            Produk ini dapat dibeli menggunakan PayPal seharga {usd_price} .
            Pastikan saldo akun PayPal kamu mencukupi sebelum melakukan
            pembelian. Berikut cara {title} di Rupiah.trade:
          </p>
          <ol>
            <li>
              Klik tombol <strong>Beli Sekarang</strong>
            </li>
            <li>
              Login ke Akun Rupiah.trade kamu. Jika belum memiliki akun kamu
              dapat membuat akun baru (
              <a
                target="__blank"
                href="https://blog.rupiah.trade/cara-membuat-akun-rupiah-trade-e0f9ce675e07"
              >
                lihat cara membuat akun
              </a>
              )
            </li>
            <li>
              Klik <strong>Transaksi Otomatis</strong>, kemudian pilih{" "}
              <strong>{product.type}</strong>,
            </li>
            {product.type === "Pulsa" || product.type === "Internet" ? (
              <li>
                Ketik nomor HP yang ingin kamu isi {product.type} kemudian klik
                logo operator yang muncul,
              </li>
            ) : (
              <li>
                Pilih <strong>{product.product_description}</strong>,
              </li>
            )}

            <li>
              Pilih <strong>{product.product_nominal}</strong> kemudian klik{" "}
              <strong>Berikutnya</strong>,
            </li>

            {product.type === "Pulsa" || product.type === "Internet" ? (
              <li>
                Klik <strong>Buat Transaksi</strong>.
              </li>
            ) : (
              <li>
                {product.type !== "Game"
                  ? `Masukkan nomor HP yang terdaftar dengan akun ${product.product_description} kamu`
                  : `Masukkan User ID / Server ID yang terdaftar dengan akun ${product.product_description} kamu`}
                .
              </li>
            )}
            <li>
              Lakukan pembayaran dengan PayPal. Tunggu beberapa saat hingga
              status transaksi berubah menjadi "Success".
            </li>
          </ol>
          <p>
            Pada pukul 00:00 - 01:00 WIB (tengah malam), beberapa server kami
            akan offline. Jadi transaksi pada waktu tersebut akan di proses
            setalah pukul 01:00 WIB.
          </p>

          <p>
            <span className=" text-sm italic">
              Catatan: Jika harga yang tertera pada halaman ini berbeda dengan
              harga yang tertera pada Aplikasi, maka harga yang sah adalah harga
              yang tertera pada aplikasi. Karena harga pada aplikasi akan
              berubah secara dinamis setiap waktu mengikuti kurs US$ ke Rupiah.
            </span>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Product;

const rateUSD = (total: number, rate: number) => {
  const paypalRate = rate - rate * 0.04;
  const fee = (total / paypalRate) * 0.039 + 0.3;
  if (total / paypalRate > 9 && total / paypalRate < 50) {
    return total / (paypalRate * 0.89) + fee;
  }
  if (total / paypalRate >= 50) {
    return total / (paypalRate * 0.94) + fee;
  }
  if (!total) {
    return 0;
  }
  return total / (paypalRate * 0.87) + fee;
};

export const pageQuery = graphql`
  query ProductQuery($id: String) {
    product(id: { eq: $id }) {
      id
      title
      featuredImage {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: WEBP
            breakpoints: [300, 500, 600]
          )
        }
      }
      type
      topup_title
      product_nominal
      product_price
      product_details
      product_description
    }
  }
`;
